import React from 'react';

import './PgaSobre.css';

function PgaSobre() {
    return (
        <div className="xtudol sobre">

            <h1>Sobre</h1>
            <br></br>

            <h3>A SMIZE TV</h3>
            <br></br>
            <p>Transmitimos os melhores canais ENTRETENIMENTO, TEATRO E MÚSICA para sua comodidade e segurança</p>
            <br></br>
            <p>Para duvidas, suporte ou criar um canal ligue</p>
            <br></br>
            <p>Rodrigo Adelino (21) 99885-1057</p>

            <p>rodrigo@bascode.com</p>
            <br></br>


            <a
      href="https://wa.me/5521998851057?text=Ol%C3%A1%21%20Gostaria%20de%20receber%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Smize%20TV.%20Pode%20me%20ajudar%3F"
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-btn"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
        className="whatsapp-icon"
      />
      Fale conosco pelo WhatsApp
    </a>
            <br></br>

            <br></br>
            
            <p>Streamize TV</p>
            <br></br>
            <br></br>

        </div>

    );
}

export default PgaSobre;