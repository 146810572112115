import React from 'react';
import { MdClose } from 'react-icons/md';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

    return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      {/* Modal Container */}
      <div className="bg-black rounded-lg shadow-lg w-full max-w-[95vw] mx-6 my-16 p-6 relative overflow-y-auto max-h-[95vh]">
        {/* Botão Fechar */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-white hover:text-gray-300"
        >
          <MdClose size={24} />
        </button>

        {/* Conteúdo do Modal */}
                {children}
      </div>
        </div>
    );
};

export default Modal;
