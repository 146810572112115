import axios from 'axios';
import { global } from './global'
import teste from "jwt-decode";

const gbrl = teste(`eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.${global.bk}.bjUFn2U7z6vfZLiLPanxQyBb_--41GNoDfr3G3SroTo`);


global.brl = gbrl



const api = axios.create({ baseURL: global.brl.REACT_APP_EIPNODEAPI });

api.interceptors.request.use((config) => {
  const headers = { ...config.headers };
  ///  console.log(global.token,'global.token')
  if (global.token) {
    headers.Authorization = `Bearer ${global.token}`;
  };
  return { ...config, headers };
})

export default api;
