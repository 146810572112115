
export const global = {

    token: '',
    brl: [],
    bk: 'eyJSRUFDVF9BUFBfRUlQTk9ERUFQSSI6Imh0dHBzOi8vYXBpLmp1ZGFoLnR2L2FwaS8iLCJSRUFDVF9BUFBfVkVSU0FPIjoiNzcwNDYwNmVlZDZhMTJlZmVkYTMzNDI4MTUyZGJkOTkiLCJSRUFDVF9BUFBfRVZJVUdVSURVUkwiOiJodHRwczovL2FwaS5pdWd1LmNvbS92MS8iLCJSRUFDVF9BUFBfRVZJVUdVIjoiOUJCQUE3RjEyQzZGNUVGNUZFRTMwRTg4MkEyRjJBMkQ0Qzk0NTRBNjVEQUZGRUM2NjhBOTMxQTlCMkRGOThDQiIsIlJFQUNUX0FQUF9FVklVR1VJRCI6IjcwRjZERTBDMjA3QTQ0MDRBNDY5MjhBRTUyQzI2RUNFIiwiUkVBQ1RfQVBQX0NIQVQiOiJodHRwczovL2FwaS5qdWRhaC50dj90b2tlbj03NzA0NjA2ZWVkNmExMmVmZWRhMzM0MjgxNTJkYmQ5OSIsIlJFQUNUX0FQUF9FVklVR1VDWCI6IjVlOGFhNWI1NDAwODMzMDliY2UwNDVhYyIsIlJFQUNUX0FQUF9GT1RPIjoiaHR0cHM6Ly9hcGkuanVkYWgudHYvdXBsb2Fkcy8iLCJSRUFDVF9BUFBfRkFDRSI6IjMyNDgzNDE2NzE4NzczNDQiLCJSRUFDVF9BUFBfRVZJUExJVkUxaW5pIjoiaHR0cHM6Ly9hcGkuanVkYWgudHYvbGl2ZS8iLCJSRUFDVF9BUFBfRVZJUExJVkUxZmltIjoiL2luZGV4Lm0zdTgiLCJSRUFDVF9BUFBfTElTVEFJRCI6IjVlOThiYzNlZThjZDdkMDI0MWJhZjBmZCIsIlJFQUNUX0FQUF9FVklQUExBWTFpbmkiOiJodHRwczovL2FwaS5qdWRhaC50di9yZWMvIiwiUkVBQ1RfQVBQX0VWSVBQTEFZMmluaSI6Imh0dHBzOi8vYXBpLmp1ZGFoLnR2L2Jsb2IvIiwiYWxnIjoiSFMyNTYifQ'

}


//
//
//{
//    "REACT_APP_EIPNODEAPI": "https://api.judah.tv/api/",
//    "REACT_APP_VERSAO": "7704606eed6a12efeda33428152dbd99",
//    "REACT_APP_EVIUGUIDURL": "https://api.iugu.com/v1/",
//    "REACT_APP_EVIUGU": "998c42ebd47ca44e11969a4dbe9ecdb3",
//    "REACT_APP_EVIUGUID": "0574140D8C634E16B8E89AD8B7FF7C35",
//    "REACT_APP_CHAT": "https://api.judah.tv/chat?token=7704606eed6a12efeda33428152dbd99",
//    "REACT_APP_EVIUGUCX": "5e8aa5b540083309bce045ac",
//    "REACT_APP_FOTO": "https://api.judah.tv/uploads/",
//    "REACT_APP_FACE": "3248341671877344",
//    "REACT_APP_EVIPLIVE1ini": "https://api.judah.tv/live/",
//    "REACT_APP_EVIPLIVE1fim": "/index.m3u8",
//    "REACT_APP_LISTAID": "5e98bc3ee8cd7d0241baf0fd",
//    "REACT_APP_EVIPPLAY1ini": "https://scl081.azureedge.net/rec/",
//    "REACT_APP_EVIPPLAY2ini": "https://scl031.azureedge.net/blob/",
//    "alg": "HS256"
//  }
